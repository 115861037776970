import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/brothers/",
      name: "brothers",
      component: () => import("./views/Brothers.vue"),
    },
    {
      path: "/brother/:id",
      name: "brother",
      component: () => import("./views/Brother.vue"),
    },
    {
      path: "/edit/:id",
      name: "edit",
      component: () => import("./views/editBrother.vue"),
    },
    {
      path: "/addnew",
      name: "addnew",
      component: () => import("./views/addNew.vue"),
    },
    {
      path: "/sign-in",
      name: "signin",
      component: () => import("./views/Signin.vue"),
    },
    {
      path: "/join",
      name: "join",
      component: () => import("./views/Join.vue"),
    },
    {
      path: "/ingewijd",
      name: "ingewijd",
      component: () => import("./views/birthDays.vue"),
    },
    {
      path: "/functions",
      name: "functions",
      component: () => import("./views/Functions.vue"),
    },
    {
      path: "/functions/edit",
      name: "functionsEdit",
      component: () => import("./views/editFunctions.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("./views/About.vue"),
    },
    {
      path: "/uploadimage/:id",
      name: "uploadimage",
      component: () => import("./views/uploadImage.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  // hier de gratis pages
  const allowedPages = ["/sign-in", "/"];
  var n = allowedPages.indexOf(to.fullPath);
  if (n == -1) {
    if (!store.state.isAuthenticated) {
      next("/");
    }
  }
  // store from page.name for dynamic routing backbutton
  store.commit("setLastPage", from.name);
  next();
});
export default router;
